import RequestError from 'JIX/errors/RequestError.js';
//import { api as _api } from 'JIX/utils.js';

// We cannot use api() from utils.js because it does not support setting the keepalive flag
async function _api(method, url, data = {}) {
    const body = new FormData();
    for (const [key, val] of Object.entries(data)) {
        body.append(key, val);
    }
    const options = {
        method,
        body,
        keepalive: 'keepalive' in Request.prototype,
    };
    const csrf = window?.Stash?.common?.csrf_token;
    if (csrf) {
        options.headers = {
            'X-CSRF-Token': csrf,
        };
    }
    const res = await fetch(url, options);
    if (res.ok) {
        return res.json();
    } else {
        throw new RequestError(method, url, res);
    }
}

export function abtestCreate(name, data = {}) {
    return _api('POST', '/abtest/create/' + name, data);
}

export function abtestActivate(id, context) {
    const data = {};
    if (context) {
        data.context = context;
    }
    return _api('POST', '/abtest/activate/' + id, data)
        .catch(_err => ({ activated: false }));
}

export function abtestSuccess(id) {
    return _api('POST', '/abtest/success/' + id)
        .catch(_err => ({ activated: false }));
}
