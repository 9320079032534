import { ref, unref, computed, onMounted } from 'vue';
import { useRefEventListener } from 'JIX/eventListener.js';


export const RESPONSIVE_BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];

export function getResponsiveBreakpoint() {
    const width = window.innerWidth;
    if (width >= 1200) {
        return 'xl';
    } else if (width >= 992) {
        return 'lg';
    } else if (width >= 768) {
        return 'md';
    } else if (width >= 576) {
        return 'sm';
    } else {
        return 'xs';
    }
}

/**
 * Compares a given breakpoint to the current one
 *
 * @param {string} str - The comparison breakpoint
 * @returns {number} A comparison
 *
 * Returns -1 if the breakpoint is lower, returns 0 if the breakpoint is
 * equal, and returns 1 if the breakpoint is greater.
 */
export function compareResponsiveBreakpoint(breakpoint, currentBreakpoint = getResponsiveBreakpoint()) {
    const idx = RESPONSIVE_BREAKPOINTS.indexOf(breakpoint);
    if (idx < 0) {
        throw Error('Invalid breakpoint');
    }

    const curIdx = RESPONSIVE_BREAKPOINTS.indexOf(currentBreakpoint);

    return Math.sign(curIdx - idx);
}

export function useResponsiveBreakpoint() {
    const breakpoint = ref('xs');
    const update = () => breakpoint.value = getResponsiveBreakpoint();
    useRefEventListener(ref(window), 'resize', update);
    onMounted(update);
    return breakpoint;
}

export function useResponsiveBreakpointUp(breakpoint) {
    const currentBreakpoint = useResponsiveBreakpoint();
    return computed(() => compareResponsiveBreakpoint(unref(breakpoint), currentBreakpoint.value) >= 0);
}

export function useResponsiveBreakpointDown(breakpoint) {
    const currentBreakpoint = useResponsiveBreakpoint();
    return computed(() => compareResponsiveBreakpoint(unref(breakpoint), currentBreakpoint.value) <= 0);
}
