/* global jobmail_popup_ready, Sentry */

import $ from 'jquery';
import 'bootstrap-multiselect';
import 'blockui';
import { createApp, shallowReactive, h } from 'vue';

import {
    getSelection as getTraveltimeSelection,
    setSelection as setTraveltimeSelection,
} from 'JIX/jobad/elements/Distance.js';
import JobAdElementsManager from 'JIX/jobad/elements/ElementsManager.js';
import DsAddressField from 'JIX/components/ds/DsAddressField.vue';

import { setCanonicalUrl } from 'JIX/utils.js';
import { abtestCreate, abtestActivate } from 'JIX/abtest/api.js';
import { compareResponsiveBreakpoint } from 'JIX/utils/breakpoints.js';

const stash = window.Stash['jobsearch/search'];

const JobSearch = function ($form, options) {

    window.addEventListener('reloadJobads', e => {
        obj.elementsManager.handleReload(e.detail.jobadWrapper);
    });

    const obj = this;    // make sure we always have a reference to the object

    /**
     * Simple toggle state to make sure that we don't trigger multiple searches
     * at the same time, e.g. by infinite scrolling.
     */
    obj.loading = false;

    /**
     * Simple toggle state to make sure we don't mess with the browser history
     * while loading a search state.
     *
     * Loading a search state (popState) triggers a search event, but searching
     * normally triggers a pushState action. This is undesirable as it would
     * push the just popped state back.
     */
    obj.enable_pushstate = true;

    /**
     * Reference to the search XHR object (if any).
     *
     * This is used to abort a running search XHR request if a new one is
     * triggered before it has completed.
     */
    obj.search_xhr = null;

    /**
     * Reference to the search form as a jQuery object.
     */
    obj.$form = $form;

    /**
     * Various options provided by the backend.
     *
     * Contains translation strings, callback URLs and more.
     */
    obj.options = options;

    /**
     * Initialize the job search features.
     */
    obj.init = function () {
        obj.initFormSubmission();
        obj.initPushState();
        //obj.initSidebarStates();
        obj.elementsManager = new JobAdElementsManager();
        obj.initTraveltime();
        obj.setupSort();
        obj.setupAbtests();
    };

    /*
     * This code replaces the values of the hit counter badges.
     */
    obj.replaceHitCounters = function (facets_obj) {
        $("#filter_sidebar span.counter").each(function() {
            const span = $(this);
            const control_id = span.attr('id');
            //console.log(control_id);
            if (control_id.indexOf('hits_') === 0) {
                const data_id = control_id.substring(5);
                let text = "";
                if (data_id.indexOf('geoareaid') === 0) {
                    text = facets_obj.areahits[data_id.substring(9)];
                } else if (data_id.indexOf('supid') === 0) {
                    text = facets_obj.suphits[data_id.substring(5)];
                } else if (data_id.indexOf('subid') === 0) {
                    text = facets_obj.subhits[data_id.substring(5)];
                } else if (data_id.indexOf('jobage') === 0) {
                    text = facets_obj.jobagehits[data_id.substring(6)];
                }
                if (text === undefined || text === null || text === "") {
                    text = "0";
                }
                const newSpan = $('<span>');
                newSpan.attr('class', 'counter');
                newSpan.attr('id', control_id);
                newSpan.append(text);
                span.replaceWith(newSpan);
            }
        });
    };

    /*
     * This code replaces the values of the link URLs.
     */
    obj.replaceUrls = function (url_maps_obj) {
        $("#filters a:not(.title,.card-header)").each(function() {
            const link = $(this);
            const control_id = link.attr('id');
            //console.log(control_id);
            let text = "";
            if (control_id.indexOf('geoareaid') === 0) {
                text = url_maps_obj.geoareaid_urls[control_id.substring(9)];
            } else if (control_id.indexOf('subid') === 0) {
                const supid_urls = url_maps_obj.subid_urls[control_id.substring(5)];
                const sup_link = link.parent().parent().parent().parent().children('a.title');
                const sup_id = sup_link.attr('id').substring(5);
                text = supid_urls[sup_id];
            } else if (control_id.indexOf('supid') === 0) {
                // This is currently used for the Adecco jobsearch
                text = url_maps_obj.supid_urls[control_id.substring(5)];
            } else if (control_id.indexOf('jobage') === 0) {
                const key = control_id.substring(6) || 'default';
                text = url_maps_obj.jobage_urls[key];
            }
            link.attr('href', text);
        });
    };

    // Insert a hidden field on the form in the hidden fields holder
    obj.insertHiddenField = function(name, value) {
        const field = $('<input>');
        field.attr('type', 'hidden');
        field.attr('value', value);
        field.attr('name', name);
        $("#search_box_hidden_fields").append(field);
    };

    // Set a field with a given name and value in the appropriate way (hidden field vs. input field) for the given name
    obj.setField = function(name, value) {
        let affected_input = null;
        if (name === 'geoareaid') {
            affected_input = $("#geoareas");
        } else if (name === 'subid') {
            affected_input = $("#category");
        } else if (name === 'jobage') {
            // Jobage is the one and only multi-select box with single-select
            affected_input = $("#jobage");
            affected_input.val(value);
            affected_input.multiselect('refresh');// This is needed to refresh the bootstrap-multiselect box
            affected_input.trigger('change');
            return;// No further processing should be done for jobage
        } else if (name === 'companyid') {
            // For other sites than StepStone and it-jobbank, companyid is a select2 field called companyids
            affected_input = $("#companies");
        } else if (name === 'flag') {
            affected_input = $("#flag");
        }
        if (affected_input !== undefined &&
            affected_input !== null &&
            affected_input.length > 0) {
            // This handles all cases of multi-select boxes with multi-select
            const values = affected_input.val() || [];
            values.push(value);
            affected_input.val(values);
            if (name === 'companyid' || name === 'geoareaid') {
                affected_input.trigger("change");// This is needed to refresh the select2 box
            } else {
                affected_input.multiselect('refresh');// This is needed to refresh the bootstrap-multiselect box
            }
        } else {
            // This handles all cases of hidden fields
            obj.insertHiddenField(name, value);
        }
    };

    // Unset a field with a given name and value in the appropriate way (hidden field vs. input field) for the given name
    obj.unsetField = function(name, value) {
        let affected_input = null;
        if (name === 'geoareaid') {
            affected_input = $("#geoareas");
        } else if (name === 'radius') {
            const $geoareaid = $("#geoareas", obj.$form);
            const val        = $geoareaid.val();
            const new_val    = $.grep(val, function(v) { return v > 0; });
            $geoareaid.val(new_val).trigger('change');
        } else if (name === 'subid') {
            affected_input = $("#category");
        } else if (name === 'companyid') {
            // For other sites than StepStone and it-jobbank, companyid is a select2 field called companyids
            affected_input = $("#companies");
        } else if (name === 'flag') {
            affected_input = $("#flag");
        }
        if (affected_input !== undefined &&
           affected_input !== null &&
           affected_input.length > 0) {
            // This handles all cases of multi-select boxes with multi-select
            const values = affected_input.val().filter(x => x !== value);
            affected_input.val(values);
            if (name === 'companyid' || name === 'geoareaid') {
                affected_input.trigger("change");// This is needed to refresh the select2 box
            } else {
                affected_input.multiselect('refresh');// This is needed to refresh the bootstrap-multiselect box
            }
        } else {
            // This handles all cases of hidden fields
            affected_input = $("#search-component :input[name='" + name + "'][value='" + value + "']");
            affected_input.remove();
        }
    };

    // Update the visual selection of a field, i.e. geoareaid, supid, subid, or jobage
    obj.setVisualSelection = function(name, value) {
        let affected_link;
        if (name === 'geoareaid') {
            affected_link = $("#" + name + value);
            affected_link.parent().attr('class', 'checkbox-holder is-selected');
        } else if (name === 'subid') {
            // There may be multiple affected links for subid, so we have to select on class name
            affected_link = $("a." + name + value);
            affected_link.parent().attr('class', 'checkbox-holder is-selected');
        } else if (name === 'supid') {
            affected_link = $("#" + name + value);
            if (affected_link.attr('class').indexOf('title') >= 0) {
                // This is the "ordinary" case where both supid and subid are used
                affected_link.attr('class', 'title is-selected');
            }
        } else if (name === 'jobage') {
            const radiobutton_holders = $("#jobages").children();
            radiobutton_holders.each(function() {
                const holder = $(this);
                const link = holder.find('a');
                if (link.attr('id') === ('jobage' + value)) {
                    holder.attr('class', 'radiobutton-holder is-selected');
                } else {
                    holder.attr('class', 'radiobutton-holder');
                }
            });
        }
    };

    // Set the selection of a field, i.e. geoareaid, supid, subid or companyid, including hidden field vs. input field
    obj.setSelection = function(name, value) {
        obj.setField(name, value);
        obj.setVisualSelection(name, value);
    };

    // Set the selections of all filter fields
    obj.setVisualAllFilterSelections = function(form_data) {
        // Clear form:
        $("#filtersholder a.title.is-selected").attr('class', 'title');
        $("#filtersholder div.checkbox-holder.is-selected").attr('class', 'checkbox-holder');
        obj.setVisualSelection('jobage', '');

        // Update form values:
        $.each(form_data, function (name, val) {
            if (name === 'geoareaid' ||
                name === 'supid' ||
                name === 'subid') {
                // When there are multiple hidden fields with the same name,
                // we get an array of values instead of a single value,
                // but we want to generate individual hidden fields
                if ($.isArray(val)) {
                    $.each(val, function(index, value) {
                        //console.log(value);
                        obj.setVisualSelection(name, value);
                    });
                } else {
                    //console.log(val);
                    obj.setVisualSelection(name, val);
                }
            } else if (name === 'jobage') {
                obj.setVisualSelection(name, val);
            }
        });
    };

    // Click handler for area, category and sub category links
    obj.clickHandlerRegionCategorySub = function(event, elem, name) {
        const elem_id = elem.attr('id');
        if (elem_id !== undefined && elem_id !== null && elem_id.indexOf(name) === 0) {
            event.preventDefault();
            const id = elem_id.substring(name.length);
            //console.log(id);
            const holder = elem.parent();
            const holder_class = holder.attr('class');
            if (holder_class.indexOf('is-selected') >= 0) {
                //holder.attr('class', 'checkbox-holder');
                obj.unsetField(name, id);
            } else {
                //holder.attr('class', 'checkbox-holder is-selected');
                obj.setField(name, id);
            }
            obj.$form.trigger('submit');
        }
    };
    // Click handler for jobage links
    obj.clickHandlerJobage = function(event, elem, name) {
        const elem_id = elem.attr('id');
        if (elem_id !== undefined && elem_id !== null && elem_id.indexOf(name) === 0) {
            event.preventDefault();
            const value = elem_id.substring(name.length);
            //obj.setVisualSelection(name, value);
            obj.setField(name, value);
            obj.$form.trigger('submit');
        }
    };

    /**
     * Initialize form submission.
     *
     * Handles manual trigger of the search event.
     */
    obj.initFormSubmission = function () {
        obj.$form.on('submit', obj.reloadResults);

        // Setup click handlers on your choice links
        $("#results").on("click", "#yourchoices a.your_choices_label", function (event) {
            event.preventDefault();
            const $elem = $(this);

            const param = $elem.data('param');
            const value = "" + $elem.data('value'); // make sure it's a string to it matches output from .val()

            if (param === 'jobage') {
                obj.setSelection('jobage', '');
                if (value === 'archive') {
                    obj.unsetField('archive', '1');
                }
            } else if (param === 'param') {
                const $input = $("#search-component :input[name='" + value + "']");
                const url = new URL($elem.attr('href'), location.href);
                const new_value = url.searchParams.get(value);
                $input.val(new_value);
            }

            if (value !== undefined && value !== null) {
                obj.unsetField(param, value);
            }

            obj.$form.trigger('submit');
        });

        const handlers = [
            ['area', 'geoareaid'],
            ['sub', 'subid'],
            ['sup', 'supid'],
            ['category'],
        ];

        for (const [label, param] of handlers) {
            $("#results").on("click", "a." + label + "_label", function (event) {
                obj.clickHandlerRegionCategorySub(event, $(this), param);
            });
        }

        // Setup click handlers on all jobage links
        $("#results").on("click", "a.jobage_label", function (event) {
            const elem = $(this);
            obj.clickHandlerJobage(event, elem, 'jobage');
        });

        // Setup click handlers on all pagination links
        $("#results").on("click", ".pagination a", function (event) {
            const elem = $(this);
            const href = elem.attr('href');
            if (href !== undefined && href !== null && (href.indexOf('&page=') >= 0 || href.indexOf('?page=') >= 0)) {
                event.preventDefault();
                const page_string = href.substring(href.indexOf(href.indexOf('&page=') >= 0 ? '&page=' : '?page=') + 6);
                const page = page_string.indexOf('&') >= 0 ? page_string.substring(href.indexOf('&')) : page_string;
                //console.log(page);
                const page_field = $("#page_field");
                page_field.val(page);
                window.scrollTo(0, 0);
                obj.$form.trigger('submit');
            }
        });
    };

    obj.sendAnalyticsEvent = function (title) {
        if (window.jixAnalytics) {
            window.jixAnalytics('pageview', { path: location.pathname + location.search, title });
        }
        if (window.pp_gemius_identifier2 && window.pp_gemius_hit) {
            window.pp_gemius_hit(window.pp_gemius_identifier2);
        }
    };

    /**
     * Initialize history pushState/popState functionality.
     *
     * Handles search update/reload when the user triggers the back/forward
     * browser history events. It also normalizes the query parameters
     * immediately by using the canonical URL.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/Guide/API/DOM/Manipulating_the_browser_history
     */
    obj.initPushState = function () {
        const search_state = obj.getSearchState();

        if (history.replaceState !== undefined) {
            // In Safari (and Chrome prior to v34, supposedly), popstate is fired on page load,
            // so we need to set the jobsearch flag to true here, in order to test for it on popstate,
            // since the popstate event fired on Safari has the state from before this replaceState
            const search_link = new URL($("#ajax_canonical_link").attr('href'), location.href);
            const search_url = search_link.pathname + search_link.search;
            history.replaceState({ jobsearch: true, search: search_state }, '', search_url);
        }

        $(window).on('popstate', function (event) {
            const state = event.originalEvent.state;
            if (state !== null && typeof state === "object" && (state.jobsearch === undefined || !state.jobsearch)) {
                return;
            }
            let form_data;
            if (state !== null && typeof state === "object") {
                form_data = state.search;
            } else {
                return;
                //form_data = search_state;
            }

            // Clear form:
            $(':input', obj.$form)
                .not(':button, :submit, :reset, :hidden, [type=checkbox]')
                .val('')
                .prop('selected', false);
            $(':checked', obj.$form).prop('checked', false);
            $("div #search_box_hidden_fields").children().remove();
            $("#filtersholder a.title.is-selected").attr('class', 'title');
            $("#filtersholder div.checkbox-holder.is-selected").attr('class', 'checkbox-holder');
            let affected_input = $('#geoareas');
            affected_input.val([]).trigger('change');
            if (options.user !== 'stedk' &&
               options.user !== 'itjdk') {
                //affected_input.val([]);
                //affected_input.multiselect('refresh');// This is needed to refresh the bootstrap-multiselect box
                affected_input = $('#category');
                affected_input.val([]);
                affected_input.multiselect('refresh');// This is needed to refresh the bootstrap-multiselect box
                obj.setVisualSelection('jobage', '');
                affected_input = $('#companies');
                affected_input.val('').trigger("change");// This is needed to refresh the select2 box
            }

            // Update form values:
            $.each(form_data, function (name, val) {
                const $el  = $(':input[name="' + name + '"]', obj.$form);
                const type = $el.attr('type');

                if (name === 'companyid' ||
                   name === 'companyids' ||
                   name === 'company' ||
                   name === 'customer_companyid' ||
                   name === 'geoareaid' ||
                   name === 'latitude' ||
                   name === 'longitude' ||
                   name === 'radius' ||
                   name === 'address' ||
                   name === 'supid' ||
                   name === 'subid' ||
                   name === 'flag' ||
                   name === 'archive' ||
                   name === 'doublet' ||
                   name === 'lang') {
                    // When there are multiple hidden fields with the same name,
                    // we get an array of values instead of a single value,
                    // but we want to generate individual hidden fields
                    if ($.isArray(val)) {
                        $.each(val, function(index, value) {
                            //console.log(value);
                            obj.setSelection(name, value);
                        });
                    } else {
                        //console.log(val);
                        obj.setSelection(name, val);
                    }
                } else {
                    switch (type) {
                        case 'checkbox':
                            if (typeof val !== "object") {
                                val = [val];
                            }
                            $.each(val, function (k, v) {
                                $el.filter('[value="' + v + '"]').prop('checked', true);
                            });
                            break;
                        case 'radio':
                            $el.filter('[value="' + val + '"]').prop('checked', true);
                            break;
                        default:
                            $el.val(val);
                            break;
                    }
                }
            });

            // Perform new search:
            obj.enable_pushstate = false;
            obj.$form.trigger('submit');
            obj.enable_pushstate = true;

            obj.sendAnalyticsEvent(state.title);
        });
    };

    /**
     * Get the "search state" from serialized form data.
     *
     * This is a Javascript object of the search parameters. Empty values are
     * filtered out.
     */
    obj.getSearchStateFromFormData = function (form_data) {
        const params = new URLSearchParams(form_data);

        const state = {};
        for (const [k, v] of params) {
            if (k !== undefined && k !== null &&
               /* !(k.indexOf('your_choice_') === 0 ) && */
               (typeof v !== 'string' || k === 'jobage' || !(v === '' || v === '0'))) {
                if (state[k]) {
                    if (!Array.isArray(state[k])) {
                        state[k] = [state[k]];
                    }
                    state[k].push(v);
                } else {
                    state[k] = v;
                }
            }
        }

        return state;
    };

    /**
     * Get the "search state".
     *
     * This is a Javascript object of the search parameters. Empty values are
     * filtered out.
     */
    obj.getSearchState = function () {
        const form_data = obj.$form.serialize();
        return obj.getSearchStateFromFormData(form_data);
    };

    /**
     * Reload search results.
     *
     * This performs a new search. It is triggered by the checkboxes in the sidebar and by the search box fields.
     */
    obj.reloadResults = function () {
        const form_data = $(this).serialize();

        // We need to copy enable_pushstate, since we read it in the Ajax call, which is asynchronous
        const local_enable_pushstate = obj.enable_pushstate;
        const state = obj.getSearchStateFromFormData(form_data);

        if (obj.search_xhr) {
            obj.search_xhr.abort();
        }

        const block_opts = {
            message: null,
            overlayCSS:  {
                backgroundColor: '#fff',
                opacity: 0.7,
                cursor: 'progress'
            },
            applyPlatformOpacityRules: false
        };

        $("#lock_while_searching").block(block_opts);

        obj.search_xhr = $.ajax({
            url: obj.options.callbacks.load,
            data: form_data
        }).done(function (data) {
            if (data.redirect) {
                window.location.href = data.redirect_url;
                return;
            }
            if (local_enable_pushstate && history.pushState !== undefined) {
                const search_link = new URL(data.ajax_canonical, location.href);
                const search_url = search_link.pathname + search_link.search;
                history.replaceState({ title: document.title, ...history.state }, ''); // Save the title from the initial pageload
                history.pushState({ jobsearch: true, search: state, title: data.title }, '', search_url);
                obj.sendAnalyticsEvent(data.title);
            }

            const cvBanner = document.getElementById('jobsearch-cv-banner-container');
            if (cvBanner) {
                const page = new URL(data.ajax_canonical).searchParams.get('page') || 1;
                cvBanner.hidden = page > 1;
            }

            $("#yourchoices").replaceWith($(data.your_choices_html.trim()));
            $("#jix_jobsearch_teaser_box").replaceWith($(data.teasers_html.trim()));
            obj.replaceHitCounters(data.job_list_facets);
            obj.replaceUrls(data.url_maps);
            $("#page_field").val('');
            setCanonicalUrl(data.link_canonical);
            $(document).attr('title', data.title);
            if (data.jobmail_popup_html !== undefined && data.jobmail_popup_html !== null) {
                $("#jobmail_popup_block").replaceWith($(data.jobmail_popup_html.trim()));
                if ($("#jobmail_popup").length) {
                    jobmail_popup_ready();
                }
            }
            obj.setVisualAllFilterSelections(state);

            $(".jix_sidebar_skyscraper").replaceWith($(data.skyscraper_html.trim()));

            $('.jix_pagination_total').replaceWith($(data.hitcount_html.trim()));
            $('.jobsearch-map-switcher').replaceWith($(data.map_switcher_html.trim()));
            $('.jobsearch-traveltime-edit').replaceWith($(data.traveltime_edit_html.trim()));
            $('.jobsearch-header').replaceWith($(data.header_html.trim()));
            $('#job_result_click_stat_buttons').replaceWith($(data.click_stat_buttons_html.trim()));

            const fadeOutPromise = new Promise(resolve => {
                $("#result_list_box").fadeOut('fast', function () {
                    resolve($(this));
                });
            });
            const fadeInPromise = new Promise(resolve => {
                $(data.result_list_box_html.trim()).fadeIn('fast', function () {
                    resolve($(this));
                });
            });

            Promise.allSettled([fadeOutPromise, fadeInPromise]).then(([fadeOutRes, fadeInRes]) => {
                fadeOutRes.value.remove();
                fadeInRes.value.insertBefore("#loading");

                fadeInRes.value.trigger('reloaded.jix.jobsearch');

                obj.elementsManager.handleReload();

                if (JIX.registerExposure) {
                    JIX.registerExposure('[data-beacon-tid]');
                } else {
                    Sentry.captureMessage("JIX.registerExposure is not defined");
                }

                obj.options.robotjob_abtest_data = data.robotjob_abtest_data;
                obj.setupAbtests();

                $("#lock_while_searching").unblock();
                document.activeElement.blur();
            });
        }).fail(function () {
            $("#lock_while_searching").unblock();
            alert(options.ajax_error_text);
        });


        return false;
    };

    obj.initTraveltime = function () {
        const modal = document.getElementById('traveltime-modal');
        if (!modal) {
            return;
        }

        const form = modal.querySelector('form');
        const transportationField = modal.querySelector('#traveltime-transportation');
        // The address field in the search form gets rendered by a Vue
        // component so we postpone querying for the element until needed to
        // avoid possible race-conditions.

        const props = shallowReactive({
            modelValue: "",
            required: true,
            'onUpdate:modelValue': (v) => props.modelValue = v,
        });
        createApp(() => h(DsAddressField, props)).mount('#traveltime-address');

        $(modal).on('show.bs.modal', () => {
            props.modelValue = $('input[name=address]', obj.$form).val();
            transportationField.value = getTraveltimeSelection();
        });

        form.addEventListener('submit', (event) => {
            event.preventDefault();

            $('input[name=address]', obj.$form).val(props.modelValue);
            setTraveltimeSelection(transportationField.value);

            obj.$form.trigger('submit');

            $(modal).modal('hide');
        });
    };

    obj.setupSort = function () {
        const results = document.querySelector('.jobsearch-results');
        const sortDropdown = results.querySelector('#sort-dropdown');
        if (sortDropdown && !sortDropdown.dataset.init) {
            const dropdownButton = sortDropdown.querySelector('button[data-toggle]');
            const dropdownItems = sortDropdown.querySelectorAll('.dropdown-item');
            const sortField = this.$form[0].querySelector('input[name=sort]');
            dropdownItems.forEach(item => {
                item.addEventListener('click', () => {
                    dropdownButton.querySelector('span').innerText = item.innerText;
                    sortField.value = item.dataset.key;
                    obj.$form.submit();
                });
            });
            sortDropdown.dataset.init = true;
        }
    };

    obj.setupAbtests = function () {
        obj.setupRobotjobAbtest();
    };

    function createJobadObserver(dataKeys) {
        return new IntersectionObserver((entries, observer) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    const jobad = entry.target;
                    for (const dataKey of dataKeys) {
                        const abtestid = jobad.dataset[dataKey];
                        if (abtestid) {
                            const context = compareResponsiveBreakpoint('md') <= 0 ? 'mobile' : 'desktop';
                            abtestActivate(abtestid, context);
                        }
                    }
                    observer.unobserve(jobad);
                }
            }
        }, {
            threshold: .2,
            root: null,
        });
    }

    function assignAbtestToJobads(jobads, observer, abtestName, dataKey) {
        if (jobads.length === 0) {
            return;
        }
        abtestCreate(abtestName, { n: jobads.length }).then(abtests => {
            jobads.forEach((jobad, i) => {
                const abtest = abtests[i];
                if (!abtest) {
                    return;
                }

                observer.observe(jobad);

                jobad.dataset[dataKey] = abtest.id;

                const links = jobad.querySelectorAll('[data-click]');
                for (const link of links) {
                    const href = new URL(link.dataset.click, location.href);
                    href.searchParams.append('abtestid', abtest.id);
                    link.dataset.click = href.toString();
                }
            });
        });
    }

    obj.setupRobotjobAbtest = function () {
        obj._jobadObserver2?.disconnect?.();
        if (!obj.options.robotjob_abtest_data?.enable) {
            return;
        }

        obj._jobadObserver2 ||= createJobadObserver(['robotjobDesignAbtestid', 'robotjobDesignAllAbtestid']);

        const jobads = [...document.querySelectorAll('.jobsearch-results [data-beacon-tid]')];
        const robotjobs = jobads.filter(x => !!x.querySelector('[data-robotjob-abtest-enabled="1"]'));
        assignAbtestToJobads(robotjobs, obj._jobadObserver2, 'jobsearch_robotjob_design', 'robotjobDesignAbtestid');
        assignAbtestToJobads(jobads, obj._jobadObserver2, 'jobsearch_robotjob_design_all', 'robotjobDesignAllAbtestid');
    };
};

$(() => {
    const jobsearch = new JobSearch($("form#jobsearch"), stash);
    jobsearch.init();
});
